import React, { useEffect } from "react";
import { useState } from "react";
import Payuframe from "./partials/frame";
import { List } from "./partials/list";
import Invoice from "../../invoice/component";

export const Payment = ({ order, old_order ,nextStage, prevStep ,setFooter,stage ,farmModules,active}) => {

  const params = new URLSearchParams(window.location.search);
  const [isChecked, setIsChecked] = useState(false);
  const [isInvoiceChecked, setIsInvoiceChecked] = useState(false);
  const [order_, setOrder_] = useState([]);


  // useEffect(() => {
  //   console.log(order_);
  //   if(order_.length > 0)
  //   {
  //     console.log("zmieniam");
  //     setFooter([
  //       { disabled:false , class:"btn-green" , action : tryOrder , text:"Potwierdź zamówienie"},
  //     ]);
  //   }
  //   return () => {
  //
  //   };
  // }, [order_]);

  const tryOrder = () => {
    
    const api_url = "https://ezezwolenie.pl/api-payu/";
    const farmid = params.get("fishingfarm");

    async function tryToPay() {
      let order = {...old_order,order:{lakes:order_,rules:"false",invoice:old_order["order"]["invoice"]}};
      const response = await fetch(api_url +farmid+ "/create-payment",{
        method: "POST",
        body: JSON.stringify(order),
      });
      const jsonData = await response.json();

      if(jsonData.status === true)
      {
        window.open(jsonData.payment_url);
        nextStage();
      }
    }

    tryToPay();
  }
  const handleInvoiceChange = event => {
    if (event.target.checked&&order_.length > 0) {
      old_order["order"]["invoice"]=1
    } else {
      old_order["order"]["invoice"]=""
    }
    setIsInvoiceChecked(current => !current);
  };

  const handleChange = event => {
    if (event.target.checked&&order_.length > 0) {
      setFooter([
        {
          class: "btn-primary",
          text: "Cofnij",
          action: prevStep,
          disabled: false,
        },
        { disabled:false , class:"btn-green" , action : tryOrder , text:"Zamawiam z obowiązkiem zapłaty"},
      ]);

    } else {
      setFooter([
        {
          class: "btn-primary",
          text: "Cofnij",
          action: prevStep,
          disabled: false,
        },
        { disabled:true , class:"btn-green" , action : tryOrder , text:"Zamawiam z obowiązkiem zapłaty"},
      ]);
    }
    setIsChecked(current => !current);
  };

  useEffect(() => {
    setFooter([ {
      class: "btn-primary",
      text: "Cofnij",
      action: prevStep,
      disabled: false,
    },
      { disabled:true , class:"btn-green" , action : tryOrder , text:"Zamawiam z obowiązkiem zapłaty"},

    ]);
    return () => {};
  }, []);

  return (
    <div className="grid grid-flow-row">

      <List order={order} setOrder={setOrder_} setFooter={setFooter} stage={stage} oldOrder={old_order} farmModules={farmModules} />
      <div className="row">
        <div className="py-4">
          <input
              value={isChecked}
              onChange={handleChange}
              type="checkbox"
          />
          <span className="ms-1  font-medium">
            Niniejszym zamawiam natychmiastowe wykonanie umowy sprzedaży E-zezwolenia na amatorski połów ryb oraz przyjmuje<br></br>
                do wiadomości, że z chwilą dokonania płatności umowa zostanie w pełni wykonana i utracę przysługujące mi prawo do odstąpienia od umowy.
            </span>
        </div>
        {farmModules.includes('ProsbyOFaktury') ?   <div className="py-1">
          <input
              value={isInvoiceChecked}
              onChange={handleInvoiceChange}
              type="checkbox"
          />
          <span className="ms-1  font-medium">
            Chcę otrzymać fakturę VAT.
            (Faktura zostanie wystawiona tylko po opłaceniu zezwolenia)
            </span>
        </div>
            :''}
      </div>

    </div>
  );
};
