


const StageBarItem = ({stage , active = false, label =""}) => {


    const class_names = active? "":"text-fishes-blue-disabled hidden md:block";


    return (
      <>
        <div className={"text-center title-blue-dark  " + class_names} >{stage}. {label}</div>
      </>
    )
  }
  
  export default StageBarItem
  