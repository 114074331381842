import React, { useEffect, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import { useRef } from "react";
import Modal from "./modal";

const NewAccount = ({
  setFooter,
  updateOrder,
  order,
  backToLogin,
  nextStage,
  createAfter = false,
  farmInfo,
}) => {
  const [page, setPage] = useState(1);
  const [text, setText] = useState("Nr karty wędkarskiej");
  const animation = useAnimationControls();
  const animation2 = useAnimationControls();
  const c = order.customer;
  const ca = order.customer.address;
  async function sequence() {
    setPage(2);
    await animation.start({ rotateY: -90 });
    await animation.start({ display: "none" });
    await animation2.start({ display: "block" });
    await animation2.start({ rotateY: 0 });
  }
  async function sequencebackward() {
    setPage(1);
    await animation2.start({ rotateY: 90 });
    await animation2.start({ display: "none" });
    await animation.start({ display: "flex" });
    await animation.start({ rotateY: 0 });
  }
  const isNextAvaliable = () => {
    if (page == 1) return c.name && c.surname;

    if (page == 2)
      return (
        c.email &&
        c.address.city &&
        c.address.street &&
        c.address.postalCode &&
        c.address.building.nr &&
        c.rules.privacy === "true" &&
        c.rules.sell === "true" &&
        order.order.rules === "true"
      );
  };

  const footerGet = () => {
    switch (page) {
      case 1:
        setFooter([
          // {
          //   class: "btn-primary",
          //   text: "Wróć do logowania",
          //   action: backToLogin,
          //   disabled: false,
          // },
          {
            class: "btn-green",
            text: "Kontynuuj",
            action: sequence,
            disabled: isNextAvaliable() ? false : true,
          },
        ]);
        break;
      case 2:
        setFooter([
          {
            class: "btn-primary",
            text: "Cofnij",
            action: sequencebackward,
            disabled: false,
          },
          {
            class: "btn-green",
            text: "Kontynuuj",
            action: nextStage,
            disabled: isNextAvaliable() ? false : true,
          },
        ]);
        break;
    }
  };
  useEffect(() => {
    footerGet();
    return () => {};
  }, [page]);

  useEffect(() => {
    footerGet();
    return () => {};
  }, [order]);

  const setData = (e) => {
    let x = {
      target: "customer." + e.target.getAttribute("name"),
      value: e.target.value,
    };

    if (e.target.getAttribute("type") == "checkbox") {
      x.value = e.target.checked;
    }

    updateOrder(x);
  };

  const setDataFull = (e) => {
    let x = {
      target: e.target.getAttribute("name"),
      value: e.target.value,
    };

    if (e.target.getAttribute("type") == "checkbox") {
      x.value = e.target.checked;
    }
    updateOrder(x);
  };

  const [regulations, setRegulations] = useState({
    privacy: false,
    regulation_app: false,
    regulation: false,
    additonal_info_min: false,
  });


  
    var dtToday = new Date();
    
        var month = dtToday.getMonth() + 1;// jan=0; feb=1 .......
        var day = dtToday.getDate();
        var year = dtToday.getFullYear() - 14;
        if(month < 10)
            month = '0' + month.toString();
        if(day < 10)
            day = '0' + day.toString();
    	var minDate = year + '-' + month + '-' + day;
     



  const [orginalEmail, setorginalEmail] = useState(order.customer.email);
  const [notorginalEmail, setnotorginalEmail] = useState(order.customer.email);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const email = useRef(null);

  const emailVerify = (e) => {
    const x = {
      target: "customer.email",
      value: "",
    };
    updateOrder(x);

    switch (e.target.dataset.verify) {
      case "source":
        setorginalEmail(e.target.value);
        break;
      case "target":
        setnotorginalEmail(e.target.value);

        if (e.target.value.toLowerCase() == orginalEmail.toLowerCase()) {
          if (validateEmail(e.target.value)) {
            email.current.style.color = "green";
            const x = {
              target: "customer.email",
              value: e.target.value.toLowerCase(),
            };
            updateOrder(x);
          }
        } else if (e.target.value == "") {
          email.current.style.color = "black";
        } else {
          email.current.style.color = "red";
        }

        break;
    }
  };
  const foreigner = (e) => {
    if (text !== "Nr karty wędkarskiej") setText("Nr karty wędkarskiej");
    else setText("Nr Paszportu");
  };


  const validateOldDate = () => {
    
  }

  const openRegulations = (e) => {
    const id_ = e.target.dataset.rule;
    const ob = {};
    ob[id_] = true;
    setRegulations({ ...regulations, ...ob });
  };

  return (
    <div>
      <div className="text-center font-medium text-lg p-2">Dane Wędkarza</div>
      <motion.div
        className="flex flex-col items-center  md:flex-row items-start bg-fishes-blue-disabled p-3 mx-auto max-w-md rounded-md shadow-md gap-2 "
        animate={animation}
        transition={{ ease: "linear", duration: 0.5 }}
      >
        <div className="h-44 w-44 bg-fishes-blue-light rounded-md ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="bg-fishes-blue-disabled"
            className="bi bi-person-bounding-box w-full h-full p-3 opacity-75 fill-fishes-blue-disabled"
            viewBox="0 0 16 16"
          >
            <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          </svg>
        </div>
        <div className=" flex flex-col h-full items-start flex-1 ms-2 ">
          <p className="py-3 font-semibold w-full text-center">Wymagane <span className="required">*</span></p>
          <div className="flex flex-row gap-2">
            <div>
              <p className=" font-semibold mb-1">Imię <span className="required">*</span></p>
              <input
                type="text"
                name="name"
                className="input-sm bg-fishes-blue-light w-full mb-1"
                onChange={setData}
                value={order.customer.name}
              />
            </div>
            <div>
              <p className=" font-semibold mb-1">Nazwisko <span className="required">*</span></p>
              <input
                type="text"
                name="surname"
                className="input-sm bg-fishes-blue-light w-full  mb-1"
                onChange={setData}
                value={order.customer.surname}
              />
            </div>
          </div>

          <p className=" font-semibold mb-1">Data urodzenia (opcjonalnie)</p>
          <input
            type="date"
            name="birth"
            max={minDate}
            onChange={setData}
            className="input-sm bg-fishes-blue-light w-full mb-1 "
            value={order.customer.birth}
          />
          <div className="w-full flex gap-3">
            <p className="font-semibold mb-1 inline-block ">Jestem Obcokrajowcem</p>
            <input
                type="checkbox"
                className="input-sm"
                onChange={foreigner}
            />
          </div>
          <div className={` w-full`}>
          <p className=" font-semibold mb-1">{text}</p>
          <input
            type="text"
            name="fishingCard"
            className="input-sm bg-fishes-blue-light w-full"
            onChange={setData}
            value={order.customer.fishingCard}
          />
          </div>
        </div>
      </motion.div>

      <motion.div
        className="flex flex-row items-start bg-fishes-blue-disabled p-3 mx-auto max-w-xl rounded-md shadow-md gap-2"
        style={{ rotateY: 90, display: "none" }}
        animate={animation2}
        transition={{ ease: "linear", duration: 0.5 }}
      >

        
        <div className=" grid grid-flow-row grid-cols-6 gap-2">

        {farmInfo.additonal_info_min.replace("	","").length > 0 ?
        (<div className="col-span-6">
        <div className="bg-fishes-blue-dark text-fishes-white py-2 px-2 font-semibold rounded-lg text-sm">
            {farmInfo.additonal_info_min}
        </div>
      </div>)
        :
        ("")}
          


          <div className="col-span-6 my-2">
                <p className="py-3 font-semibold">Wymagane <span className="required">*</span></p>
            <div className="col-span-12">
              <p className="py-1 font-semibold">Na ten adres e-mail wyślemy zezwolenie. Upewnij się, że e-mail jest poprawny.</p>
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <p className=" font-semibold mb-1">Email <span className="required">*</span></p>
            <input
              ref={email}
              type="text"
              className="input-sm bg-fishes-blue-light w-full "
              placeholder="przyklad@email.com"
              onChange={emailVerify}
              data-verify="source"
              value={orginalEmail}
            />
          </div>

          <div className="col-span-6 md:col-span-3">
            <p className=" font-semibold mb-1">Powtórz email <span className="required">*</span></p>
            <input
              type="text"
              className="input-sm bg-fishes-blue-light w-full "
              placeholder="przyklad@email.com"
              onChange={emailVerify}
              value={notorginalEmail}
              data-verify="target"
            />
          </div>

          <div className="col-span-3 md:col-span-2">
            <p className="font-semibold mb-1">Miasto <span className="required">*</span></p>
            <input
              type="text"
              onChange={setData}
              name="address.city"
              className="input-sm bg-fishes-blue-light w-full "
              placeholder="Warszawa"
              value={order.customer.address.city}
            />
          </div>
          <div className="col-span-3 md:col-span-2">
            <p className=" font-semibold mb-1">Ulica <span className="required">*</span></p>
            <input
              type="text"
              onChange={setData}
              name="address.street"
              className="input-sm bg-fishes-blue-light w-full "
              placeholder="warszawska"
              value={order.customer.address.street}
            />
          </div>
          <div className="col-span-2">
            <p className="font-semibold mb-1">Kod pocztowy <span className="required">*</span></p>
            <input
              type="text"
              onChange={setData}
              name="address.postalCode"
              className="input-sm bg-fishes-blue-light w-full "
              placeholder="XX-XXX"
              value={order.customer.address.postalCode}
            />
          </div>

          <div className="col-span-2">
            <p className="font-semibold mb-1">Budynek <span className="required">*</span></p>
            <input
              type="text"
              onChange={setData}
              name="address.building.nr"
              className="input-sm bg-fishes-blue-light w-full "
              placeholder="12"
              value={order.customer.address.building.nr}
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <p className="font-semibold mb-1">Mieszkanie</p>
            <input
              type="text"
              onChange={setData}
              name="address.building.apartment"
              className="input-sm bg-fishes-blue-light w-full"
              placeholder="0"
              value={order.customer.address.building.apartment}
            />
          </div>

          <div className="col-span-3"></div>

          <div className="col-span-6 my-1"></div>

          <div className="col-span-6 md:col-span-3">
            <input
              type="checkbox"
              onChange={setData}
              name="rules.sell"
              checked={order.customer.rules.sell === "true"}
            />
            <span className="ms-1">
              Akceptuję{" "}
              <b
                data-rule="regulation_app"
                onClick={openRegulations}
                className="cursor-pointer"
              >
             <br></br>regulamin sprzedaży e-zezwoleń
              </b>
              <span className="required">*</span>
            </span>
          </div>

          <div className="col-span-6 md:col-span-3">
            <input
              type="checkbox"
              onChange={setData}
              name="rules.privacy"
              checked={order.customer.rules.privacy === "true"}
            />
            <span className="ms-1">
              Akceptuję{" "}
              <b
                data-rule="privacy"
                onClick={openRegulations}
                className="cursor-pointer"
              >
                politykę prywatności
              </b>
              <span className="required">*</span>
            </span>
          </div>

          <div className="col-span-6">
            <input
              type="checkbox"
              onChange={setDataFull}
              name="order.rules"
              checked={order.order.rules === "true"}
            />
            <span className="ms-1 ">
              Akceptuję{" "}
              <b
                data-rule="regulation"
                onClick={openRegulations}
                className="cursor-pointer"
              >
                regulamin łowiska
              </b>
              <span className="required">*</span>
            </span>
          </div>

          {/* <div className="col-span-6 my-2  mb-1">
            <span className="text-sm font-bold text-fishes-blue-dark">Opcjonalne</span>
          </div>

        <div className="col-span-6">
          <p className="text-sm font-semibold mb-1">Zniżki</p>

          <select className="input bg-fishes-blue-light w-full">
            <option value="0">Brak zniżki</option>
            <option value="1">50% Uczeń/Student</option>
            <option value="2">50% powyżej 75 lat </option>
            <option value="3">50% Inwalida wojenny </option>
          </select>

          <p className="text-xs font-semibold mb-1 mt-3">* Zniżki zastosowują się automatycznie jeśli dane gospodartwo na nie zezwala</p>


        </div>

          {!createAfter && isNextAvaliable()?
          <motion.div className="col-span-6 mt-2" initial={{opacity:0,scale:0}} animate={{scale:1,opacity:1}} transition={{ duration: 0.5}}>
            <span className="text-sm font-semibold text-center block mb-2">Stwórz konto jednym kliknięciem i zyskaj dostęp do szybkich e-zezwoleń</span>
            <button className="btn btn-primary mx-auto block">
              Załóż konto
            </button>
          </motion.div>
          :""
          } */}
        </div>
        {regulations.privacy ? (
          <Modal
            header={"Polityka prywatności"}
            whenClose={() => {
              setRegulations({ ...regulations, ...{ privacy: false } });
            }}
          >
            <div className="py-3 px-4 overflow-auto">
              <div
                className="tailwind-remove "
                dangerouslySetInnerHTML={{ __html: farmInfo.privacy }}
              />
            </div>
          </Modal>
        ) : (
          ""
        )}

        {regulations.regulation_app ? (
          <Modal
            header={"Regulamin aplikacji"}
            whenClose={() => {
              setRegulations({ ...regulations, ...{ regulation_app: false } });
            }}
          >
            <div className="py-3 px-4 overflow-auto">
              <div
                className="tailwind-remove "
                dangerouslySetInnerHTML={{ __html: farmInfo.regulation_app }}
              />
            </div>
          </Modal>
        ) : (
          ""
        )}

        {regulations.regulation ? (
          <Modal
            header={"Regulamin łowiska"}
            whenClose={() => {
              setRegulations({ ...regulations, ...{ regulation: false } });
            }}
          >
            <div className="py-3 px-4 overflow-auto">
              <div
                className="tailwind-remove "
                dangerouslySetInnerHTML={{ __html: farmInfo.regulation }}
              />
            </div>
          </Modal>
        ) : (
          ""
        )}
      </motion.div>
    </div>
  );
};

export default NewAccount;
