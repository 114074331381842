import React from "react";
import {Discount} from "./discount";

const Description = ({ ico, hint, value, }) => {
  return (
    <div className="col-span-4 md:col-span-2 items-center border-2 px-2 py-1 flex rounded-lg gap-3 border-fishes-blue-disabled text-fishes-blue-disabled font-semibold darker-color">
      <div>
        <i className={ico}></i>
      </div>
      <div className="w-[2px] h-full bg-fishes-blue-disabled"></div>
      <div className="text-sm">{hint}</div>
      <div className="ms-auto text-sm ">{value} </div>
    </div>
  );
};
const Item = ({ lake, price,originalPrice,discountName, date, method, season, stage,removeItem }) => {
  return lake !== undefined && lake !== null ? (
    <div>
        <div className="col-span-4 px-3">
            <div className="flex flex-row justify-between font-semibold items-end ">
                <p>{lake.name}</p>

                <p className="text-end">
                    <span style={{color:"red"}}>{discountName}</span><p className="line-through"> {originalPrice ? originalPrice + " zł" :""}</p>
                     {price !== undefined && price !== null ? price + "zł" : ""} <span>
                     {stage ? (
                         <button onClick={() => removeItem(lake.id)} className="btn-red px-2 rounded-lg ml-auto">
                             Usuń
                         </button>
                     ) : (
                         <></>
                     )}
                </span></p>
            </div>
            <div className="bg-fishes-blue-disabled mt-1 h-1"></div>
        </div>
    <div className="grid grid-flow-row grid-cols-4 grid-rows-2 p-3 gap-2 gap-y-1 mt-2 ">
      <Description
        ico={"bi bi-cloud-drizzle"}
        hint="Sezon"
        value={season !== null ? season.name : ""}
      ></Description>

      <Description
        ico={"bi bi-hand-index"}
        hint="Metoda"
        value={method !== null ? method.name : ""}
      ></Description>

      <Description
        ico={"bi bi-calendar-date"}
        hint="Od"
        value={date.from !== null ? date.from.toLocaleDateString("pl-PL") : ""}
      ></Description>

      <Description
        ico={"bi bi-calendar-date"}
        hint="Do"
        value={date.to !== null ? date.to.toLocaleDateString("pl-PL") : ""}
      ></Description>
    </div>
    </div>
  ) : (
    <></>
  );
};

export default Item;
