import React, { useEffect } from "react";
import { useState } from "react";

import "react-calendar/dist/Calendar.css";
import Invoice from "../../invoice/component";
import Order from "../../order/component";

const LakeChoice = ({
    active,
    setActive,
    items,
    setItems,
  setFooter,
  nextStage,
  prevStage,
  setOrder,
  addOrder = () => {},
    farmModules
}) => {
useEffect(() => {
  setFooter([{
    class: "btn-primary",
    text: "Cofnij",
    action: prevStage,
    disabled: false,
  },]);
}, []);


    const [itemRemoved, setItemRemoved] = useState(null);
  const updateOrderItem = (item) => {
    setItems((prev) => {
      prev[prev.length - 1] = { ...prev[prev.length - 1], ...item };
      return [...prev];
    });
    setItemRemoved(null)
      setFooter([{
          class: "btn-primary",
          text: "Cofnij",
          action: prevStage,
          disabled: true,
      },])
  };
  const updateActiveButton = (status) => {
   setActive(status)
  }
  const createNewItem = () => {
    setItems((prev) => {
      return [
        ...prev,
        {
          lake: null,
          price: null,
          date: { from: null, to: null },
          method: null,
          season: null,
          duration:null,
        },
      ];
    });
    setActive(true);
      setFooter([{
          class: "btn-primary",
          text: "Cofnij",
          action: prevStage,
          disabled: false,
      },])
  };

  const nextStage_= () => {
    setOrder(items.slice(0,-1));
    nextStage();
  }
  const removeItem = (id) => {
    const itemsFiltered= items.filter((e)=>e["lake"]!==null);
      const newOrder = itemsFiltered.filter((item) =>item["lake"]["id"] !== id);
       setItems(newOrder)
        createNewItem()
        setItemRemoved(id);
        if (items.length===2){
          setActive(false)
        }
  }

  return (
    <div className="grid grid-cols-3 gap-3 px-3 py-1">
      <Order
          updateActiveButton={updateActiveButton}
        onItemChange={updateOrderItem}
        items={items}
        onOrderItemComplete={createNewItem}
          itemRemoved={itemRemoved}
          setFooter={setFooter}
          prevStage={prevStage}
      />
      <Invoice items={[...items]} disabled={active} onNextStage={nextStage_} removeItem={removeItem} farmModules={farmModules} />
    </div>
  );
};

export default LakeChoice;
