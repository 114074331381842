import React from 'react'
import { useEffect } from 'react'

const StageFooter = ({actions = []}) => {

  useEffect(() => {
    return () => { };
  }, [actions]);
 
  return (
    <div className={"cardFooter " + (actions.length == 0? "hidden":"" )}>

    <div className='w-full mt-4 flex justify-between sm:justify-end gap-2'>
        {
          actions.map((e,index) => {
            return <button key={index} disabled={e.disabled} className={'btn ' + e.class} onClick={e.action}>{e.text}</button>
          })
        }
    </div>
    </div>
  )

}

export default StageFooter