import React from 'react'

const Modal = ({children,header,footer,whenClose}) => {

  return (
    <div style={{maxHeight: "75vh",height: "fit-content"}} className=' flex flex-col content-between items-center  shadow-md fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-screen-md w-full bg-fishes-blue-light border-fishes-blue-dark border-2 p-3 rounded-md h-1/4'>
        <button onClick={whenClose} className=' btn btn-primary absolute right-0 me-2'>X</button>
        <div className='bg-fishes-blue-disabled px-2 py-1 rounded-md shadow-md w-3/4 mb-3'>
            <div className='mx-auto w-fit'>
                {header}
            </div>
        </div>
            {children}
        <div>
            {footer}
        </div>
    </div>
  )
}

export default Modal