import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { List } from "./waterChoice/list";

const Choice = ({items,name,ico,status,onSelect}) => {
  
    const [selected, setSelected] = useState(null);
    
    const onSelect_ = (e,listId) => {
        setSelected(e);
        onSelect(e);
    }
    useEffect(() => {
      
    }, [items]);
  return (
    <>
    <div className={`relative py-2 px-1 ${!status? "blur-[2px]" : ""}`}>
      {!status? 
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full bg-[#395c8d] opacity-70 rounded-lg">
      </div>
      :
      <></>
      }

      <List onSelect={onSelect_} active={status} ico={ico} items={items} name={name}></List>
    </div>
    </>
  );
};

export default Choice;
