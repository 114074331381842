import React from 'react'

const StageBarGrid = ({children}) => {
  return (
    <div className='grid grid-flow-row grid-cols-1 md:grid-cols-4'>
        {children}
    </div>
  )
}

export default StageBarGrid