import React from 'react'
import Card from '../card'
import StageBarGrid from './grid'
import StageBarItem from './item'

 const StageBar = ({items,stage}) => {
  
   return (
    <Card className="mt-4">
        <StageBarGrid>
            {items.map((item,key) => {
                const is_active = key + 1 <= stage;
               return <StageBarItem  active={is_active} key={key} stage={key + 1} label={item} />
            })}
        </StageBarGrid>
    </Card>
  )
}

export { StageBar , StageBarGrid , StageBarItem}
