import React, { useState } from 'react'
import Card from '../card'
import LakeChoice from '../pages/lake-choice/lake-choice'
import { Payment } from '../pages/payment/payment'
import StageFooter from './footer'
import InfoPage from './pages/info'
import Statement from '../pages/statement/statement'

const Stage = ({stage, prevStage, nextStage ,setOrder, order, farmInfo,setOrderManually,farmModules}) => {

  const stages = [];

  const [footerConfig, setfooterConfig] = useState([]);

  const addToOrder = (order) => {
    setOrderManually((e) => {
      e.order.lakes = order; 
      return {...e};
    });
  }

  const [order_, setOrder_] = useState(null);
  const [items, setItems] = useState([
    {
      lake: null,
      price: null,
      date: { from: null, to: null },
      method: null,
      season: null,
      duration:null,
    },
  ]);
  const [active, setActive] = useState(false);
  const previousLakeChoice =() =>{
    setActive(true)
    prevStage()
  }
  return (
    <div className='h-full'>

        <Card className="mt-4 py-6 h-full">
          
          {stage.stage === 1?
          <InfoPage setFooter={setfooterConfig} farmInfo={farmInfo} setOrder={setOrder} order={order} nextStage={nextStage} />
          : ""}
          {stage.stage === 2?
          <LakeChoice nextStage={nextStage} prevStage={prevStage} setOrder={setOrder_} setFooter={setfooterConfig} addOrder={addToOrder} setItems={setItems} items={items} active={active} setActive={setActive} farmModules={farmModules}/>
          :""}
          {stage.stage === 3?
          <Payment nextStage={nextStage} order={order_} old_order={order} setFooter={setfooterConfig} prevStep={previousLakeChoice} stage={stage.stage} farmModules={farmModules} active={active}/>
          :""}
          {stage.stage === 4?
              <Statement setFooter={setfooterConfig} />
              :""}
          <StageFooter actions={footerConfig} setFooter={setfooterConfig} />

        </Card>
    </div>
  )
}

export { Stage , StageFooter }