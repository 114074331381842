import {useEffect} from "react";

const Statement = ({setFooter}) => {
    useEffect(() => {

        setFooter([]);
        return () => {

        };
    }, []);

    return (

        <div className=" w-full flex flex-col items-center">
            <h2 className="font-bold">Dziękujemy za zakup e-zezwoleń wędkarskich! </h2>  <br></br>
                <p className="p-2">Dokończ płatność w nowym oknie.</p>
                {/*  <p className="p-4">  Cieszymy się, że skorzystałeś z naszych usług, zamawiając E-zezwolenie wędkarskie.</p>*/}
                {/*    <p className="p-1">Po prawidłowym opłaceniu zamówienia, dokument <b>zostanie wysłany na adres email podany podczas zakupu.</b></p>*/}
                {/*    <p className="p-1"> <b>   Prosimy sprawdzać swoją skrzynkę odbiorczą, w tym folder "Spam" , aby upewnić się, że otrzymasz niezbędne informacje.</b>*/}
                {/* </p>*/}
                {/*    <p className="p-4">*/}
                {/*    Dziękujemy za zaufanie i życzymy udanych połowów. W razie pytań lub potrzeby dodatkowej pomocy, prosimy o kontakt. </p><p> Pełne dane kontaktowe znajdziesz na naszej stronie internetowej.*/}
                {/*    </p>*/}
                {/*<p className="p-1">*/}
                {/*W razie pytań czy potrzeby dodatkowej pomocy, jesteśmy do Twojej dyspozycji. Życzymy pełnych sukcesów połowów!*/}
                {/*</p>*/}
        </div>
    );
};
export default Statement;