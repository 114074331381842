import React, { useEffect, useState } from "react";

import { List } from "../order/waterChoice/list";
import CalendarControl from "./calendarControl";

const CalenarComponent = ({
  order,
  seasons,
  methods,
  durations,
  onRefresh,
  onGoBack,
  onAgree,
  ...props
}) => {
  
  const [actualDuration, setActualDuration] = useState(null);
  const [seasonSettings, setSeasonSettings] = useState(null);
  const [durationSelected, setdurationSelected] = useState(false);

  useEffect(() => {
    const elem = seasons.get.find((e) => {
      return e.id === order.season; 
    });
    
    setSeasonSettings(elem);
    return () => {
    
    };
  }, []);


  const refresh = (item, toOrder) => {
    onRefresh(item, toOrder);
  };

  const handleSeason = (e) => {
    setSeasonSettings(e);
    refresh({ season: e.id, last_action: "set_season" }, { season: e });
  };

  const handleMethod = (e) => {
    refresh({ method: e.id, last_action: "set_method" }, { method: e});
  };

  const handleDuration = (e) => {
    setdurationSelected(true);
    refresh(
      { duration: e.id, last_action: "set_duration" },
      { duration: e }
    );
    if(e.id !== null)
    {
      setActualDuration((prev) => {
        return {...prev,...e}
      });
    }
    else
    {
      setActualDuration((prev) => {
        return null
      });
    }
  };


  return (
    <div className="flex flex-col items-center gap-3">
      <div className=" flex flex-row gap-8 flex-wrap justify-center">
        {/*<List*/}
        {/*  className="bg-fishes-gray px-4 py-1 rounded-md"*/}
        {/*  items={seasons.get}*/}
        {/*  active={true}*/}
        {/*  name={"Wybrany sezon"}*/}
        {/*  ico={false}*/}
        {/*  autoSelect={true}*/}
        {/*  preSelected={order.season}*/}
        {/*  onSelect={handleSeason}*/}
        {/*/>*/}
        {/*<List*/}
        {/*  className="bg-fishes-gray px-4 py-1 rounded-md"*/}
        {/*  items={methods.get}*/}
        {/*  active={true}*/}
        {/*  name={"Metoda połowu"}*/}
        {/*  ico={false}*/}
        {/*  preSelected={order.method}*/}
        {/*  autoSelect={true}*/}
        {/*  onSelect={handleMethod}*/}
        {/*/>*/}
        <List
          className={`bg-fishes-gray px-4 py-1 rounded-md `}
          items={durations.get}
          active={true}
          name={"Długość zezwolenia"}
          ico={false}
          autoSelect={true}
          onSelect={handleDuration}
          onGoBack={onGoBack}
        />
      </div>
      <div
        className={
          (order.season !== null) && (order.method !== null) &&
          order.duration !== null
            ? "w-full"
            : "blur-[2px] w-auto relative"
        }
      >
        {(order.season !== null) && (order.method !== null) &&
        order.duration !== null ? (
          <></>
        ) : (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full bg-[#395c8d] opacity-70 rounded-lg"></div>
        )}
        <CalendarControl duration_settings={actualDuration} season_settings={seasonSettings} onDateSelect={refresh} ></CalendarControl>
      </div>
      <div className="flex justify-between w-full">
        <button className="bg-fishes-gray text-fishes-black px-4 py-1 font-medium rounded-lg" onClick={onGoBack}>Cofnij</button>
        <button className="btn-primary px-4 py-1 font-medium rounded-lg" onClick={onAgree} disabled={order.duration !== null && order.lake !== null && order.method !== null && order.season !== null? false: true}>Zatwierdź</button>
      </div>
    </div>
  );
};

export default CalenarComponent;
