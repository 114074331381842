import React, {useEffect, useState} from "react";
import Item from "./item";
import {Discount} from "./discount";

const Invoice = ({ items,disabled ,onNextStage,col_span="col-span-3 md:col-span-1",stage,oldOrder,removeItem,farmModules}) => {
    const [prices, setPrices] = useState([]);
    const [total, setTotal] = useState(0);
    const itemsFiltered= items.filter((e)=>e["duration"]!==null);
    const json=itemsFiltered.map((e)=>{
            return {
                duration: e.duration.id,
                lake: e.lake.id,
                method: e.method.id,
                season: e.season.id,
            };
    })
    useEffect(() => {
        getPrice(json)
    }, [onNextStage]);

    function getTotalPrice(res)
    {
        let price=0
        res.forEach((e)=>{
            price+=e.price
        })
        setTotal(price)
    }
    function setPricesOnItems(res){
        let i=0;
        res.forEach(e=>{
            itemsFiltered[i]['price']=e.price;
            i++;
        })
    }
    function setSpecialPrices(res){
        setPrices(res)
        setPricesOnItems(res)
        let onlyAllSeason= itemsFiltered.filter((e)=>e["duration"]['duration']['fullSeason']===true);
        const methodsMap = [];
        onlyAllSeason.forEach(item => {
            if (!methodsMap[item.method.id]) {
                delete item.originalPrice
                delete item.discountName
                methodsMap[item.method.id] = [];
            }
            if (methodsMap[item.method.id].length===1){
                item.originalPrice=item.price;
                item.price=(70 * item.price) / 100;
                item.discountName="30% zniżki na zezwolenie na cały sezon z tą samą metodą połowu"
                methodsMap[item.method.id].push(item);
            }
            else if (methodsMap[item.method.id].length>1){
                item.originalPrice=item.price;
                item.price=(50 * item.price) / 100;
                item.discountName="50% zniżki na zezwolenie na cały sezon z tą samą metodą połowu"
                methodsMap[item.method.id].push(item);
            }
            else{
                methodsMap[item.method.id].push(item);
            }
        });
        getTotalPrice(itemsFiltered);
    }
    async function getPrice(json) {
        const params = new URLSearchParams(window.location.search);
        const api_url = "https://ezezwolenie.pl/app-api/";
        const farmid = params.get("fishingfarm");
        const response = await fetch(api_url + farmid + "/get_price", {
            method: "POST",
            body: JSON.stringify({order:json} ),
        });
        const res = await response.json();
        if (farmModules.includes('CharzykowyDedykowaneZnizki')){
            return setSpecialPrices(res)
        }
        if (res.length>0){
            setPrices(res);
            setPricesOnItems(res)
            getTotalPrice(res);
        }
        else{
            setTotal(0);
        }
    }

  return (
    <div className={`overflow-y-auto  ${col_span} bg-fishes-white rounded-lg max-h-full`}>
      <p className="text-center text-2xl font-semibold my-3">Twoje zamówienie</p>
        {itemsFiltered.map((e, k) => {

            return <Item  key={k} {...{...e}} stage={disabled} removeItem={removeItem}></Item>;
        })}
        <p className="text-end px-2 font-semibold my-2">Razem: {total} zł</p>
        {stage === 3?  <Discount  total={total} oldOrder={oldOrder} />: null }
      {disabled ? (
        <button onClick={onNextStage} class="btn-green px-4 py-2 my-2 rounded-lg mx-auto block">
          Przejdź do podsumowania
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Invoice;
