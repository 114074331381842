import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Invoice from "../../../invoice/component";

export const List = ({ order,setOrder,stage,oldOrder,farmModules }) => {

  const order_transform = (order) =>
  {
    const order_copy = order.map((e, k) => {
        return {lake:e.lake,price:e.price,duration:{obj:e.duration,start:e.date.from.toISOString().slice(0,10),end:e.date.to.toISOString().slice(0,10)},method:e.method} ;
    })
    return order_copy;
  }

  useEffect(() => {
      const x = order_transform(order);
      setOrder(x);
    return () => {
    };
  }, []);
  //
  return (
    <Invoice items={order} col_span="col-span-2" stage={stage} oldOrder={oldOrder} farmModules={farmModules}></Invoice>

  );
};
