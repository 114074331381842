import React from 'react'

const Card = ({children,className}) => {
  return (
    <div className={'card-blue-light ' + className}>
        {children}
    </div>
  )
}

export default Card