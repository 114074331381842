import React, { useState ,useEffect} from "react";
import LoginPanel from "./_partails/login";
import NewAccount from "./_partails/newAccount";

const InfoPage = ({setFooter,setOrder,order ,nextStage,farmInfo}) => {


  const [stage, setstage] = useState(1);
  


  const handleStage = () => {
    setstage(stage + 1);
  }
  const goToLogin = () =>
  {
    setstage(0);
  }
  
  return (
    
    <div className=" w-full flex flex-col items-center">
      <div className="grid grid-rows-1 grid-flow-col gap-4 w-full">
      {/*{*/}
      {/*  stage === 0 ? */}
      {/*        <LoginPanel setFooter={setFooter} afterLogIn={nextStage}>*/}
      {/*          <button onClick={handleStage} className='btn btn-outline-green mt-3'>*/}
      {/*                  Kup bez logowania*/}
      {/*          </button>*/}
      {/*        </LoginPanel>*/}
      {/*  : ""*/}
      {/*}*/}
      {
        stage === 1 && farmInfo?
               <div className="col-span-2">
          <div className='title-blue-dark text-center mb-2'>
            {/*Kup bez logowania*/}
          </div>
          <NewAccount farmInfo={farmInfo} setFooter={setFooter} updateOrder={setOrder} order={order} backToLogin={goToLogin} nextStage={nextStage}/>
        </div> 
        : ""
      }

        

      </div>
    </div>
  );
};

export default InfoPage;
